<template>
  <nav class="navbar is-dark is-fixed-top is-mobile">
    <div class="navbar-menu is-mobile">
      <div class="navbar-start">
        <router-link to="/" class="navbar-item">
          CaFM
          <small class="ml-1 has-text-grey-light">{{
            user_store.user.name
          }}</small>
        </router-link>
      </div>
      <div class="navbar-menu" v-if="user_store.user.is_active == 1">
        <div class="navbar-start">
          <router-link
            to="/home"
            class="navbar-item"
            v-if="user_store.is_loggedin"
          >
            Dashboard
          </router-link>
          <router-link
            to="/contracts"
            class="navbar-item"
            v-if="user_store.is_loggedin"
          >
            Contracts
          </router-link>
          <router-link
            to="/scedule"
            class="navbar-item"
            v-if="user_store.is_loggedin"
          >
            Scedule
          </router-link>
          <router-link
            to="/customers"
            class="navbar-item"
            v-if="user_store.is_loggedin"
          >
            Customers
          </router-link>
        </div>
      </div>
      <div class="navbar-end" v-if="user_store.session_check == 1">
        <router-link
          to="/login"
          class="navbar-item"
          v-if="!user_store.is_loggedin"
        >
          Login
        </router-link>
        <router-link to="/help" class="navbar-item"> Help </router-link>
        <a
          class="navbar-item"
          @click.prevent="logout"
          v-if="user_store.is_loggedin || user_store.user.is_active == 0"
          >Logout</a
        >
      </div>
      <div class="navbar-end" v-if="user_store.session_check == -1">
        <div class="navbar-item has-text-info">
          <i class="fa-solid fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </nav>
  <Cookies v-if="!has_cookies"></Cookies>
</template>

<script>
import Api from "@/services/Api";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    Cookies: defineAsyncComponent(() => import("@/components/Cookies.vue")),
  },
  data() {
    return {
      user_store: storeToRefs(useUserStore()),
    };
  },
  computed: {
    has_cookies() {
      return localStorage.cookies;
    },
  },
  created() {},
  methods: {
    clearLocalStorage() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
    logout() {
      Api(true)
        .post("user/logout", {})
        .finally(() => {
          this.clearLocalStorage();
          this.$router.push({ path: "/login" });
          this.user_store.is_loggedin = false;
          this.user_store.user = {};
        });
    },
  },
};
</script>

<style scoped>
.navbar.is-dark {
  background: #2b5177 !important;
}
</style>
